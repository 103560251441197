$(document).on('turbo:load', ()=> {
  if ($("#dashboard-container").length) { new Dashboard($("#dashboard-container")); }
});

class Dashboard {
  constructor(element) {
    this.init_posts_overview_panel = this.init_posts_overview_panel.bind(this);
    this.init_posts_overview_modals = this.init_posts_overview_modals.bind(this);
    this.init_posts_overview_user_select = this.init_posts_overview_user_select.bind(this);
    this.init_on_the_radar_panel = this.init_on_the_radar_panel.bind(this);
    this.load_on_the_radar_list = this.load_on_the_radar_list.bind(this);
    this.show_promoted_feature_modal = this.show_promoted_feature_modal.bind(this);
    this.init_promoted_feature_modal = this.init_promoted_feature_modal.bind(this);
    this.container = element;
    this.init_posts_overview_panel();
    this.init_on_the_radar_panel();
    this.show_promoted_feature_modal();
    this.init_promoted_feature_modal();
  }

  init_posts_overview_panel() {
    this.init_posts_overview_modals();
    this.init_posts_overview_user_select();

    const panel = this.container.find("#posts-overview-panel");

    panel.find(".dashboard-user-filter select").on("change", e => {
      const assigned_to_id = panel.find(".dashboard-user-filter select").val();
      $.get("/dashboard/posts_overview_container", { assigned_to_id }, data=> {
        panel.find("#posts-overview-container").replaceWith(data).find('[data-toggle="tooltip"]').tooltip();
      });
    });
  }

  init_posts_overview_modals() {
    const panel = $('.posts-overview-row');

    panel.on('click', function(e) {
      const { state } = e.currentTarget.dataset;
      const modal_to_open = '#' + state + '-posts';

      $(modal_to_open).modal().show();

      $.get('/dashboard/posts_overview_list', { state, assigned_to_id: $('.dropdown-menu .selected').data('user') }).then(data => {
        const container = $('.posts-list-container');
        container.html(data).find('[data-toggle="tooltip"]').tooltip();
        $('.posts-list-loading').remove();
      });
    });
  }

  init_posts_overview_user_select() {
    const _this = this;
    const user_selection = $('.js-filter-user-dropdown');

    user_selection.on('click', function() {
      const element = $(this);
      const user = element.data('user');
      $('.dropdown-menu .selected').removeClass('selected');
      element.addClass('selected');

      if (user) {
        $('.icon-filter').addClass('enabled');
      } else {
        $('.icon-filter').removeClass('enabled');
      }

      $.get('/dashboard/posts_overview_container', {assigned_to_id: user}).then(data => {
        $('#posts-overview-container').replaceWith(data).find('[data-toggle="tooltip"]').tooltip();
        _this.init_posts_overview_modals();
      });
    });
  }

  init_on_the_radar_panel() {
    const panel = this.container.find("#on-the-radar-panel");

    panel.on("click", ".load-on-the-radar-list", e=> {
      e.preventDefault();
      this.load_on_the_radar_list($(e.target).attr("data-date"));
    });

    panel.find(".dashboard-calendar-filter select").on("change", e=> {
      this.load_on_the_radar_list();
    });
  }

  load_on_the_radar_list(date) {
    const panel = this.container.find("#on-the-radar-panel");
    const calendar_id = panel.find(".dashboard-calendar-filter select").val();
    if (!date) { date = panel.find("#on-the-radar-contents").attr("data-date"); }

    $.get("/dashboard/on_the_radar_list", { date, calendar_id }, data=> {
      panel.find("#on-the-radar-contents").attr("data-date", date).html(data).find('[data-toggle="tooltip"]').tooltip();
    });
  }

  show_promoted_feature_modal() {
    $(".promoted-feature-modal").modal("show");
  }

  init_promoted_feature_modal() {
    this.container.on("click", ".promoted-feature-modal input[type='submit']", e => {
      const modal = $(e.target).closest(".promoted-feature-modal");
      const calendar_id = modal.find("select[name='calendar_id']").val();
      const url = modal.attr("data-path-for-feature").replace(":id", calendar_id);

      modal.on("hidden.bs.modal", () => Turbo.visit(url));
      modal.modal("hide");
    });
  }
}
